<template>
<div>
    <v-col cols="12">
        <v-row justify="center">
            <v-col cols="12" lg="12" md="12" style="text-align-last: center;">
                <iframe title="Reporte Comercial" width="1000" height="541.25" :src="src" frameborder="0" allowFullScreen="true"></iframe>
            </v-col>
        </v-row>
    </v-col>
</div>
</template>

<script>
import _sGenParam from "@/services/General/ParameterService";
export default {
    name: "",
    components: {

    },
    props: {},
    data() {
        return {
            itemsType: [],
            headerType: [],
            src: ""

        }
    },
    created() {
        this.loadParameter()

    },
    mounted() {
      
    },

    methods: {

        loadParameter() {
            this.src = "https://app.powerbi.com/view?r=eyJrIjoiNmViODQzNGUtM2Q0Yy00YmI1LWFmNjQtNmE0MjQxODU3MGMyIiwidCI6IjMxMjYyYWYzLTVlMGUtNDQwZi1hZGExLTIwYWUwNTFiNGQ0YiJ9";
            /*  _sGenParam
                 .search({
                     PrmName: "srcReporteComercialBI"
                 }, this.$fun.getUserID())
                 .then((resp) => {
                     if (resp.status == 200) {
                         this.src = resp.data.PrmValue;
                     }
                 }); */
        },

    },

};
</script>
